<script setup>
import { ref, computed, onMounted } from "vue";
import { Search } from "@element-plus/icons-vue";
import PaginationComp from "@/components/PaginationComp.vue";
import { getProData, getUrbanArea } from "@/api/data";

const inputSearch = ref("");
const currentPage = ref(1);
const isLoading = ref(false);
const pageSize = ref(10);
const totalItems = ref(0);
const tableData = ref([]);
const filterSubsidy = ref("");
const filterAssit = ref("");
const urbanArea = ref([]);
const urbans = ref([]);
const areas = ref([]);
const filterUrban = ref("");
const filterArea = ref("");
const random = ref(false);
const selectedDate = ref("");
const numBegin = ref(0);
const numEnd = ref(200);
const excludedUrbanValues = ref(["其它区域", "全省", "省级"]);

const fetchDataParams = computed(() => ({
  page: currentPage.value,
  input: inputSearch.value,
  filterSubsidy: filterSubsidy.value,
  filterAssit: filterAssit.value,
  filterUrban: filterUrban.value,
  filterArea: filterArea.value,
  random: random.value,
  selectedDate: selectedDate.value,
  numBegin: numBegin.value,
  numEnd: numEnd.value,
}));

//区间选择
function debounce(fn, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

const handleChangeWordCount = (type, val) => {
  if (type === 0) numBegin.value = val;
  if (type === 1) numEnd.value = val;
  fetchTableData();
};

const debouncedHandleChangeWordCount = debounce(handleChangeWordCount, 500);
//获取数据
const fetchTableData = async () => {
  isLoading.value = true;
  try {
    const response = await getProData(fetchDataParams.value);
    tableData.value = response.data.data;
    totalItems.value = response.data.totalCount;
  } catch (error) {
    console.error("Error:", error);
  } finally {
    isLoading.value = false;
  }
};

//获取市区
const fetchUrbanArea = async () => {
  try {
    const response = await getUrbanArea();
    urbanArea.value = response.data;
    urbans.value = Object.keys(urbanArea.value);
  } catch (error) {
    console.error("Error:", error);
  }
};

const handlePageChange = ({ currentPage: newPage, pageSize: newSize }) => {
  currentPage.value = newPage;
  pageSize.value = newSize;
  random.value = false;
  fetchTableData();
};

const getFilter = (type, val) => {
  random.value = false;
  currentPage.value = 1;
  if (type == 0) filterSubsidy.value = val;
  if (type == 1) filterAssit.value = val;
  if (type == 2) inputSearch.value = val;
  if (type == 3) {
    filterUrban.value = val;
    areas.value = urbanArea.value[filterUrban.value];
    filterArea.value = "";
  }
  if (type == 4) filterArea.value = val;
  if (type == 5) random.value = val;
  if (type == 6) selectedDate.value = val;
  fetchTableData();
};

//处理ptimeint
const convertUnixTimestampToDate = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);
  date.setHours(date.getHours() + 8);
  const year = date.getUTCFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

//返回上一页
const goBack = () => {
  window.history.back();
};
onMounted(async () => {
  await fetchTableData();
  await fetchUrbanArea();
  debouncedHandleChangeWordCount();
});
</script>
<template>
  <div style="margin: 10px 60px">
    <div class="mrg-bot"><el-button plain @click="goBack">返回</el-button></div>
    <div class="mrg-bot">
      <el-input
        v-model="inputSearch"
        style="max-width: 643px"
        placeholder="请输入内容..."
        @keyup.enter="getFilter(2, inputSearch)"
      >
        <template #append
          ><el-button :icon="Search" @click="getFilter(2, inputSearch)"
        /></template>
      </el-input>
    </div>
    <div class="slider-container">
      <span class="label">区间个数：</span>
      <el-input-number
        v-model="numBegin"
        :min="1"
        :max="200"
        size="small"
        controls-position="right"
        @change="debouncedHandleChangeWordCount(0, numBegin)"
      />
      &nbsp;至&nbsp;
      <el-input-number
        v-model="numEnd"
        :min="1"
        :max="200"
        size="small"
        controls-position="right"
        @change="debouncedHandleChangeWordCount(1, numEnd)"
      />
    </div>
    <div class="page-button">
      <div class="pagination-container">
        <PaginationComp
          :total-items="totalItems"
          :initial-page-size="pageSize"
          v-model="currentPage"
          :disabled="isLoading"
          @page-change="handlePageChange"
        />
      </div>
      <div>
        <el-button type="primary" round @click="getFilter(5, true)"
          >随机获取</el-button
        >
      </div>
    </div>

    <div class="table-responsive" v-loading="isLoading">
      <table class="table">
        <colgroup>
          <col width="80" />
          <col width="300" />
          <col width="140" />
          <col width="140" />
          <col width="180" />
          <col width="180" />
          <col width="180" />
        </colgroup>
        <thead>
          <tr>
            <th>编号</th>
            <th>标题</th>
            <th>
              <div class="header-container">
                <div class="header-label">市</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterUrban"
                    placeholder="选择"
                    @change="getFilter(3, filterUrban)"
                  >
                    <el-option
                      :key="'all'"
                      label="全省"
                      value="全省"
                    ></el-option>
                    <el-option
                      :key="'proLevel'"
                      label="省级"
                      value="省级"
                    ></el-option>
                    <el-option
                      v-for="(name, index) in urbans"
                      :key="index"
                      :label="name"
                      :value="name"
                    ></el-option>
                    <el-option
                      :key="'other'"
                      label="其它区域"
                      value="其它区域"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>
              <div class="header-container">
                <div class="header-label">区</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterArea"
                    placeholder="选择"
                    @change="getFilter(4, filterArea)"
                  >
                    <el-option
                      v-if="!excludedUrbanValues.includes(filterUrban)"
                      :key="'all'"
                      label="全市"
                      value="全市"
                    ></el-option>
                    <el-option
                      v-for="(area, index) in areas"
                      :key="index"
                      :label="area.name"
                      :value="area.name"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>
              <div class="header-container">
                <!-- <div class="header-label">发布时间</div> -->

                <div class="header-controls">
                  <el-date-picker
                    v-model="selectedDate"
                    type="date"
                    placeholder="选择日期"
                    width="260"
                    @change="getFilter(6, selectedDate)"
                  />
                </div>
              </div>
            </th>
            <th>
              <div class="header-container">
                <div class="header-label">补助通知</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterSubsidy"
                    placeholder="选择"
                    @change="getFilter(0, filterSubsidy)"
                  >
                    <el-option label="全部" value="all"></el-option>
                    <el-option label="通过" value="oneUnknown"></el-option>
                    <el-option label="一定是" value="1"></el-option>
                    <el-option label="不确定" value="unknown"></el-option>
                    <el-option label="一定不是" value="0"></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>
              <div class="header-container">
                <div class="header-label">市区匹配</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterAssit"
                    placeholder="选择"
                    @change="getFilter(1, filterAssit)"
                  >
                    <el-option label="全部" value="all"></el-option>
                    <el-option label="通过" value="1"></el-option>
                    <el-option label="排除" value="0"></el-option>
                  </el-select>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <template v-if="tableData.length > 0">
          <tbody v-for="(item, index) in tableData" :key="index">
            <tr>
              <th>
                {{ (currentPage - 1) * pageSize + index + 1 }}
              </th>
              <th>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="点击跳转详情"
                  placement="top"
                >
                  <div class="text-ellipsis">
                    <a
                      target="_blank"
                      :href="item.post_url"
                      style="color: #606266; text-decoration: none"
                      >{{ item.title }}</a
                    >
                  </div>
                </el-tooltip>
              </th>
              <th>{{ item.urban }}</th>
              <th style="line-height: 25px">{{ item.area }}</th>
              <th>{{ convertUnixTimestampToDate(item.ptimeint) }}</th>
              <th>{{ item.type_1 }}</th>
              <th style="line-height: 25px">{{ item.type_2 }}</th>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td colspan="7">无数据...</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>
<style scoped>
.pagination-container {
  display: flex;
  justify-content: left;
  margin: 10px 0;
}
.mrg-bot {
  margin-bottom: 20px;
}
.table-responsive {
  min-width: 1200px;
  overflow: auto;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #ebeef5;
  text-align: left;
  padding: 10px;
  vertical-align: middle;
  color: #909399;
}
.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 25px;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-label {
  flex: 1;
}

.header-controls {
  width: 90px;
}
.page-button {
  display: flex;
  justify-content: space-between;
  width: 643px;
  align-items: center;
}
::v-deep .el-date-editor {
  width: 145px !important;
}
.slider-container {
  display: flex;
  align-items: center;
}
</style>
